import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-existing-strategy"
export default class extends Controller {
  static targets = ["implementationRequired",
                    "implementation",
                    
                    "recommendationAccepted",
                    "recommendationAcceptedDetails",
                    "reasonForDecliningRecommendation"
                    ]

  connect() {
    console.log("ExistingStrategyController connected!");

    this.handleImplementationChange();
    this.element.addEventListener("change", () => this.handleImplementationChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());
  }

  handleImplementationChange() {
    if (this.hasImplementationRequiredTarget && this.hasImplementationTarget) {
      const implementationRequiredValue = this.implementationRequiredTarget.value;

      console.log(`Implementation Required: ${implementationRequiredValue}`);

      // Perform logic based on the checkbox values
      if (implementationRequiredValue === 'true') {
        // Show
        this.implementationTarget.classList.remove("hidden");
      } else {
        // Hide
        this.implementationTarget.classList.add("hidden");
      }
    }
  }

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget && this.hasReasonForDecliningRecommendationTarget) {
      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show if_yes_with_changes_please_note_below_the_details field only
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
        this.reasonForDecliningRecommendationTarget.classList.add("hidden");
      } else if (recommendationAcceptedValue === 'no') {
        // Show reason_for_declining_recommendation field only
        this.reasonForDecliningRecommendationTarget.classList.remove("hidden");
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      } else {
        // Hide both fields
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
        this.reasonForDecliningRecommendationTarget.classList.add("hidden");
      }
    }
  }

}
