const print = () => window.print();
const autoloadScript = () => { 
  const printBtn = document.getElementById("print");

  if(!printBtn) return;

  printBtn.addEventListener("click", print);
}

// Listen for both DOMContentLoaded
document.addEventListener('DOMContentLoaded', autoloadScript);
document.addEventListener('turbo:render', autoloadScript);