import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-existing-product"
export default class extends Controller {
  static values = { productType: String }

  static targets = ["implementation_required",
                    "Implementation",

                    "recommendationAccepted",
                    "recommendationAcceptedDetails",
                    "reasonForDecliningRecommendation"
                   ]

  connect() {
    console.log("ExistingProductController connected!");

    this.handleImplementationCheckboxChange();
    this.element.addEventListener("change", () => this.handleImplementationCheckboxChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());
  }

  handleImplementationCheckboxChange() {
    if (this.hasImplementation_requiredTarget && this.hasImplementationTarget) {

      const implementation_requiredValue = this.implementation_requiredTarget.value;

      console.log(`Implementation Required: ${implementation_requiredValue}`);

      // Perform logic based on the checkbox values
      if (implementation_requiredValue === "true") {
        // Show 
        this.ImplementationTarget.classList.remove("hidden");
      } else {
        // Hide 
        this.ImplementationTarget.classList.add("hidden");
      }
    }
  }  

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget && this.hasReasonForDecliningRecommendationTarget) {

      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show if_yes_with_changes_please_note_below_the_details field
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
        this.reasonForDecliningRecommendationTarget.classList.add("hidden");
      } else if (recommendationAcceptedValue === 'no') {
        // Show reason_for_declining_recommendation field
        this.reasonForDecliningRecommendationTarget.classList.remove("hidden");
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      } else {
        // Hide both fields
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
        this.reasonForDecliningRecommendationTarget.classList.add("hidden");
      }
    }
  }  
}